<template>
  <cz-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('customer.digitalArchive.signerAuthTitle')"
    :width="500"
    inset
    :loading="loading"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <div class="text-body-1">
        {{
          $t('customer.digitalArchive.signerAuthMessage', [
            user.fullName,
            user.email
          ])
        }}
      </div>
      <v-form class="mt-4" @submit.prevent="$emit('on-auth', form)">
        <validation-provider
          rules="required"
          :name="$t('customer.digitalArchive.signerUsername')"
          v-slot="{ errors }"
        >
          <cz-form-field
            large
            required
            :label="$t('customer.digitalArchive.signerUsername')"
            :icon-src="mdiEmailOutline"
          >
            <cz-input
              v-model="form.signerId"
              :error-messages="errors"
              :prepend-inner-icon="mdiAccountCircleOutline"
            />
          </cz-form-field>
        </validation-provider>
        <validation-provider
          rules="required"
          :name="$t('customer.digitalArchive.signerPassword')"
          v-slot="{ errors }"
        >
          <cz-form-field
            large
            required
            :label="$t('customer.digitalArchive.signerPassword')"
            :icon-src="mdiEmailOutline"
          >
            <cz-input
              v-model="form.signerPassword"
              :error-messages="errors"
              type="password"
              :prepend-inner-icon="mdiLockOutline"
            />
          </cz-form-field>
        </validation-provider>
        <cz-alert v-if="errorMessage" type="error" dismissible>
          {{ errorMessage }}
        </cz-alert>
        <div class="mt-4 d-flex justify-space-between align-end">
          <cz-button
            :title="$t('customer.digitalArchive.signerAuthSubmitTitle')"
            color="primary"
            large
            type="submit"
            :disabled="invalid || loading"
          />
          <img :src="personalIdImage" :width="120" />
        </div>
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzButton,
  CzAlert
} from '@/components';
import { mdiAccountCircleOutline, mdiSecurity, mdiLockOutline } from '@mdi/js';
import personalIdImage from '@/assets/personalid.png';
export default {
  name: 'DigitalSignerAuthDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzButton,
    CzAlert
  },
  props: {
    user: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    errorMessage: {
      type: String
    }
  },
  data() {
    return {
      personalIdImage,
      mdiAccountCircleOutline,
      mdiSecurity,
      mdiLockOutline,
      form: {
        signerId: '',
        signerPassword: ''
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
