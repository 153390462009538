<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems">
    <cz-alert type="info" outlined>
      <template #prepend>
        <cz-icon :src="mdiInformationOutline" />
      </template>
      <span class="mx-2" v-html="$t('customer.digitalArchive.note')" />
    </cz-alert>

    <cz-data-table
      class="mt-10"
      height="calc(100vh - 350px)"
      v-bind="signersTable"
    >
      <template #item.actions="{ item }">
        <div class="d-flex" style="column-gap: 10px">
          <cz-button
            :title="
              item.isDigitalArchiveSigner
                ? $t('customer.digitalArchive.setAsSignerAgain')
                : $t('customer.digitalArchive.setAsSigner')
            "
            :icon-src="mdiFileSign"
            class="font-weight-bold"
            color="button"
            text
            @click="onSetSignerClicked(item)"
          />
          <cz-button
            v-if="item.isDigitalArchiveSigner"
            color="negative"
            :title="$t('customer.digitalArchive.removeSigner')"
            :icon-src="mdiCloseCircleOutline"
            text
            @click="onRemoveSignerClicked(item)"
          />
        </div>
      </template>
      <template #item.roles="{ item }">
        <cz-chip
          v-for="role in item.roles"
          :key="role._id"
          color="primary"
          class="ml-2"
          :text="role.roleName"
          small
        />
      </template>

      <template #item.isDigitalArchiveSigner="{ item }">
        <cz-checkbox :input-value="item.isDigitalArchiveSigner" readonly />
      </template>
    </cz-data-table>

    <digital-signer-auth-dialog
      v-if="signerAuthDialog.show"
      v-model="signerAuthDialog.show"
      v-bind="signerAuthDialog"
      @close="signerAuthDialog.show = false"
      @on-auth="onSignerAuth"
    />

    <cz-prompt-dialog
      v-if="removeSignerDialog.show"
      v-model="removeSignerDialog.show"
      v-bind="removeSignerDialog"
      @cancel="removeSignerDialog.show = false"
      @done="removeSelectedSigner"
    />
  </content-layout>
</template>

<script>
import {
  mdiInformationOutline,
  mdiFileSign,
  mdiCloseCircleOutline
} from '@mdi/js';
import {
  CzIcon,
  CzAlert,
  CzDataTable,
  CzButton,
  CzChip,
  CzCheckbox,
  CzPromptDialog
} from '@/components';
import {
  getUsers,
  getUserRoles,
  saveDigitalArchiveAuth,
  updateUser
} from '@/core/api';
import DigitalSignerAuthDialog from '../components/DigitalSignerAuthDialog.vue';
import { mapFields } from 'vuex-map-fields';
import uniqBy from 'lodash.uniqby';
import RoleType from '@/shared/types/RoleType';
import { mapActions } from 'vuex';

const signerAuthDialogInitialData = {
  show: false,
  user: null,
  loading: false,
  errorMessage: ''
};

Object.freeze(signerAuthDialogInitialData);

export default {
  name: 'DigitalArchiveSignersPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzPromptDialog,
    DigitalSignerAuthDialog,
    CzAlert,
    CzCheckbox,
    CzIcon,
    CzDataTable,
    CzButton,
    CzChip
  },
  computed: {
    ...mapFields('auth', ['accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.digitalArchiveSigners'),
          disabled: true,
          exact: true
        }
      ];
    }
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      mdiFileSign,
      mdiCloseCircleOutline,
      mdiInformationOutline,
      removeSignerDialog: {
        loading: false,
        show: false,
        title: this.$t('customer.digitalArchive.removeSignerPromptTitle'),
        message: '',
        doneButtonTitle: this.$t('common.remove'),
        user: null
      },
      signersTable: {
        loading: false,
        items: [],
        columns: [
          {
            text: this.$t('customer.userManagement.userFullName'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'fullName'
          },
          {
            text: this.$t('customer.userManagement.userEmail'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'email'
          },
          {
            text: this.$t('customer.userManagement.userRoles'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'roles'
          },
          {
            text: this.$t('customer.digitalArchive.isSigner'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'isDigitalArchiveSigner'
          },
          {
            text: '',
            value: 'actions'
          }
        ]
      },
      signerAuthDialog: structuredClone(signerAuthDialogInitialData)
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage']),
    async loadData() {
      try {
        this.signersTable.loading = true;
        const { data: roles } = await getUserRoles({
          accountId: this.accountId,
          role: {
            $in: [RoleType.CUSTOMER_PURCHASER, RoleType.CUSTOMER_AUTHORIZER]
          },
          $select: ['userId']
        });
        const userIds = uniqBy(roles, 'userId').map((item) => item.userId);

        const { data: users } = await getUsers({
          _id: { $in: userIds },
          accountId: this.accountId
        });

        this.signersTable.items = users;
      } finally {
        this.signersTable.loading = false;
      }
    },
    onSetSignerClicked(user) {
      this.signerAuthDialog.user = user;
      this.signerAuthDialog.show = true;
    },
    onRemoveSignerClicked(user) {
      this.removeSignerDialog.message = this.$t(
        'customer.digitalArchive.removeSignerPromptMessage',
        [user.email]
      );
      this.removeSignerDialog.user = structuredClone(user);
      this.removeSignerDialog.show = true;
    },
    async removeSelectedSigner() {
      try {
        this.removeSignerDialog.loading = true;
        const user = await updateUser(this.removeSignerDialog.user._id, {
          accountId: this.accountId,
          digitalArchiveSignerId: undefined,
          digitalArchiveSignerPassword: undefined,
          isDigitalArchiveSigner: false
        });
        this.removeSignerDialog.show = false;
        const index = this.signersTable.items.findIndex(
          (item) => item._id === this.removeSignerDialog.user._id
        );
        if (index !== -1) {
          const _signers = structuredClone(this.signersTable.items);
          _signers[index] = user;
          this.signersTable.items = _signers;
        }
      } finally {
        this.removeSignerDialog.loading = false;
      }
    },
    async onSignerAuth(authForm) {
      try {
        this.signerAuthDialog.loading = true;
        const { user } = await saveDigitalArchiveAuth({
          ...authForm,
          userId: this.signerAuthDialog.user._id,
          accountId: this.accountId
        });

        this.signerAuthDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('customer.digitalArchive.signerSetSuccess', [user.email])
        );
        // find signer index and replace it with the new one
        const index = this.signersTable.items.findIndex(
          (item) => item._id === user._id
        );
        if (index !== -1) {
          const _signers = structuredClone(this.signersTable.items);
          _signers[index] = user;
          this.signersTable.items = _signers;
        }
      } catch (error) {
        this.signerAuthDialog.errorMessage = '';
        this.$nextTick(() => {
          this.signerAuthDialog.errorMessage = error.message;
        });
      } finally {
        this.signerAuthDialog.loading = false;
      }
    }
  },
  watch: {
    'signerAuthDialog.show': {
      handler(value, oldVal) {
        if (oldVal && !value) {
          this.signerAuthDialog = structuredClone(signerAuthDialogInitialData);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
